<template>
    <div v-if="visibility">
        <div class="form-group border-top">
            <label class="light-text pt-3" for="" :class="required">{{option.label}}</label>
            <input
                :placeholder="option.placeholder" 
                type="text" 
                class="form-control rounded-field" 
                v-model="response"
                :required="required" 
                @change="handleResponse">
        </div>
    </div>
</template>
<script>
import {visibility, props, data, required, handleResponse} from '@mixins/custom-options'
export default {
    mixins: [visibility, props, data, required, handleResponse],
}
</script>