<template>
<div v-if="visibility">
    <div class="form-group border-top">
        <label  class="light-text pt-3" for="" :class="required">{{option.label}}</label>
        <textarea
            :required="required" 
            :placeholder="option.placeholder"
            cols="30" 
            rows="5" 
            class="form-control rounded-field" v-model="response"
            @change="handleResponse"></textarea>
    </div>
</div>
</template>
<script>
import {visibility, props, data, required, handleResponse} from '@mixins/custom-options'
export default {
    mixins: [visibility, props, data, required, handleResponse],
}
</script>