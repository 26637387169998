<template>
    <div class="row" v-if="visibility">
        <div class="col-md-12">
            <div class="form-group border-top">
                <label class="light-text pt-3" for="" :class="required">{{option.label}}</label>
                <select
                    :required="required"
                    v-model="response" 
                    class="form-control single-option-selector rounded-field"
                    @change="handleResponse">
                        <option 
                            v-for="(field, key) in proxyFields"
                            :value="field.value" :key="key">
                            {{field.title}}
                        </option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
import {visibility, fields, props, data, required, handleResponse} from '@mixins/custom-options'
export default {
    mixins: [visibility, fields, props, data, required, handleResponse]
}
</script>