<template>
<div class="product-options rounded-desing">
    <div class="form-group">
        <label for="">{{option.label}}</label>
        <input type="file" class="form-control">
    </div>
</div>
</template>
<script>
export default {
    props: ['option'],
    data() {
        return {
            file: ''
        }
    },
}
</script>