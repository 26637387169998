<template>
    <div v-if="visibility">
        <div class="form-group border-top">
            <label class="light-text pt-3" for="" :class="required">{{option.label}}</label>
            <br>
            <div class="form-check form-check-inline" v-for="(field, key) in proxyFields" :key="key">
                <input
                    :required="required" 
                    type="checkbox" 
                    class="form-check-input" 
                    :value="field.value" 
                    v-model="response"
                    @change="handleResponse">
                <label class="form-check-label" for="">{{field.title}}</label>
            </div>
        </div>
    </div>
</template>
<script>
import {visibility, fields, props, dataArray, required, handleResponse} from '@mixins/custom-options'
export default {
    mixins: [visibility, fields, props, dataArray, required, handleResponse]
}
</script>
