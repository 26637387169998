<template>
    <div class="row">
        <div class="col-md-12 mt-2" v-for="(option, key) in proxyOptions" :key="key">
            <div>
                <component :is="option.type" :option="option" @changed="handleResponse"></component>
            </div>
        </div>
        <input type="hidden" name="custom_options" :value="finalResponses">
    </div>
</template>
<script>
import textField from './CustomOptions/FieldTypes/TextField';
import textArea from './CustomOptions/FieldTypes/TextArea';
import file from './CustomOptions/FieldTypes/File';
import radioButtons from './CustomOptions/FieldTypes/Radio';
import checkBox from './CustomOptions/FieldTypes/Checkbox';
import dropList from './CustomOptions/FieldTypes/DropList';
export default {
    props: ['options'],
    data() {
        return {
            responses: [],
        }
    },
    computed: {
        proxyOptions() {
            return JSON.parse(this.options)
        },
        optionCount() {
            return this.proxyOptions.length;
        },
        optionClass() {
            return this.optionCount > 1 ? "col-md-6" : "col-md-12";
        },
        finalResponses() {
            return JSON.stringify(this.responses)
        }
    },
    mounted() {
        this.proxyOptions.forEach((option) => {
            this.responses.push({
                id: option.id,
                name: option.name,
                label: option.label,
                response: null,
                error: this.checkForValidation(option)
            })
        })
    },
    methods: {
        checkForValidation(option) {
            if(option.required == 1 && option.visibility == 1) {
                return {
                    status: true,
                    id: option.id,
                    message: option.label+' is required',
                }
            } else {
                return {
                    status: false
                }
            }
        },
        handleResponse(payload) {
            let element = this.responses.find((response) => {
                return response.id == payload.id
            });
            if(element != undefined) {
                element.response = payload.response;
                element.error = payload.error;
            }
        }
    },
    components: {
        textField,
        radioButtons,
        textArea,
        file,
        checkBox,
        dropList
    }
}
</script>
<style>
    .form-group label.light-text {
        margin-bottom: 5px;
        color: #d1d1d1;
        font-size: 12px;
    }
    .form-control.rounded-field {
        background: #f3f4f5;
        border: none;
        font-weight: bold;
        border-radius: 25px;
        padding-right: 28px;
    }
    label.required::after {
        color: red;
        content: " *";
    }
</style>