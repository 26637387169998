const visibility = {
    computed: {
        visibility() {
            return this.option.visibility == 0 ? false : true;
        },
    }
}

const required = {
    computed: {
        required() {
            return this.option.required == 1 ? 'required' : false;
        },
        error() {
            if(this.option.required == 1 && this.response == null || this.response == '') {
                return {
                    id: this.option.id,
                    message: this.option.label+' is required',
                    status: true
                }
            } else {
                return {
                    status: false
                }
            }
        },
    }
}

const fields = {
    computed: {
        proxyFields() {
            return JSON.parse(this.option.extra);
        },
    },
}

const props = {
    props: ['option'],
}

const data = {
    data() {
        return {
            response: ''
        }
    },
}

const dataArray = {
    data() {
        return {
            response: []
        }
    },
}

const handleResponse = {
    methods: {
        handleResponse() {
            let response = this.response;
            if(this.response.isArray) {
                response = JSON.stringify(this.response);
            }
            this.$emit('changed', {'id': this.option.id, 'response': response, error: this.error})
        }
    }
}

export {visibility, fields, data, props, dataArray, required, handleResponse};